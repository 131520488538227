/*
 * Globals
 */

@media (min-width: 48em) {
  html {
    font-size: 18px;
  }
}

body {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #555;
  background-color: #f9f9f9;
}

#root {
  background: #FFF;
}

a {
  color: #158CBA;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #158CBA;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}


h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

/*
 * Header/nav
 */

header {
  margin-bottom: 2rem;
  -webkit-box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
          box-shadow: inset 0 -.1rem .25rem rgba(0,0,0,.1);
}

.navbar-brand {
  display: block;
}

.app-logo {
  margin-right: .75rem;
}

.nav-link {
  padding: 1rem;
}


/*
 * Main area
 */

main {
  margin-bottom: 2rem;
}
main p:last-child,
main ul:last-child,
main ol:last-child {
  margin-bottom: 0;
}


/*
 * Footer
 */

footer {
  padding: 1.5rem 0;
  color: #999;
  /* text-align: center; */
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
footer p:last-child {
  margin-bottom: 0;
}


/*
 * Homepage
 */

.appstore-link {
  display: block;
  max-width: 10em;
  margin: 2em auto;
}

.appstore-link img {
  width: 100%;
}

.home-carousel {
  max-width: 20rem;
  margin: 0 auto 20px auto;
}

.home-carousel .carousel .slide {
  background: transparent;
  padding: 10px 24px;
}

.home-carousel .carousel .slide img {
  /* margin: 0 30; */
}

.home-carousel .carousel .control-next.control-arrow:before {
  border-left: 8px solid #555;
}

.home-carousel .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #555;
}

.home-carousel .carousel .thumb.selected, .home-carousel .carousel .thumb:hover {
  border: 3px solid #555;
}

.home-carousel .carousel .control-dots {
  padding: 0;
}

.home-carousel .carousel .control-dots .dot {
  background: #555;
}

.home-carousel .carousel .slider-wrapper {
  margin-bottom: 25px;
}
